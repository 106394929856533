import * as C from "@sp-crm/core";
import { produce } from "immer";
import { Action } from "redux";
import { Actions } from "../actions";
import { LoadPermissionsAction } from "../actions/permissions";

export interface PermissionsState {
    permissions: C.PermissionList;
    permissionsLoadedOnce: boolean;
}

export function permissionsReducer(
    originalState: PermissionsState,
    action: Action,
): PermissionsState {
    if (!originalState || action.type === Actions[Actions.LOGOUT]) {
        originalState = {
            permissions: {
                regions: [],
            },
            permissionsLoadedOnce: false,
        };
    }
    return produce(originalState, state => {
        if (action.type === Actions[Actions.LOAD_PERMISSIONS_FINISH]) {
            const castAction = action as LoadPermissionsAction;
            state.permissions = castAction.data.permissions;
            state.permissionsLoadedOnce = true;
        }
    });
}
