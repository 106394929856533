import { produce } from "immer";
import { Action } from "redux";
import { RequestDescription } from "../../types/request-description";
import { Actions } from "../actions";
import {
    GlobalSuccessNotificationClear,
    GlobalSuccessNotificationCreate,
} from "../actions/global-notifications";
import { RequestFailAcknowledgeAction, RequestFailAction } from "../actions/types";

export interface GlobalNotificationsState {
    faildRequests: { [id: string]: RequestDescription };
    unauthorizedRequests: { [id: string]: RequestDescription };
    successes: { [id: string]: { messageTitle: string; messageBody: string } };
}

export function globalNotificationsReducer(
    originalState: GlobalNotificationsState,
    action: Action,
): GlobalNotificationsState {
    if (!originalState || action.type === Actions[Actions.LOGOUT]) {
        originalState = { faildRequests: {}, unauthorizedRequests: {}, successes: {} };
    }
    return produce(originalState, state => {
        if (action.type === Actions[Actions.REQUEST_FAIL]) {
            const castAction = action as RequestFailAction;
            state.faildRequests[castAction.requestId] = {
                started: castAction.started,
                ended: castAction.ended,
                status: castAction.status,
                requestId: castAction.requestId,
                correlationId: castAction.correlationId,
            };
        }

        if (action.type === Actions[Actions.REQUEST_FAIL_ACKNOWLEDGE]) {
            delete state.faildRequests[(action as RequestFailAcknowledgeAction).eventId];
        }

        if (action.type === Actions[Actions.REQUEST_UNAUTHORIZED]) {
            const castAction = action as RequestFailAction;
            state.unauthorizedRequests[castAction.requestId] = {
                started: castAction.started,
                ended: castAction.ended,
                status: castAction.status,
                requestId: castAction.requestId,
                correlationId: castAction.correlationId,
            };
        }

        if (action.type === Actions[Actions.REQUEST_UNAUTHORIZED_ACKNOWLEDGE]) {
            delete state.unauthorizedRequests[
                (action as RequestFailAcknowledgeAction).eventId
            ];
        }

        if (action.type === Actions[Actions.GLOBAL_SUCCESS_CLEAR]) {
            delete state.successes[(action as GlobalSuccessNotificationClear).messageId];
        }

        if (action.type === Actions[Actions.GLOBAL_SUCCESS_NOTIFY]) {
            const castAction = action as GlobalSuccessNotificationCreate;
            state.successes[castAction.messageId] = castAction;
        }
    });
}
