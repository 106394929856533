import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EVERYONE_SENTINEL } from "@sp-crm/core";
import { SelectableUser } from "components/shared/user-select";
import {
    AdvancedSearchCondition,
    ReferenceBusinessSearchRequest,
    ReferenceContactSearchRequest,
} from "generated/graphql";

export interface ReferenceFilter {
    search: string;
    orgTypes: string[];
    contactCondition: AdvancedSearchCondition | null;
    businessCondition: AdvancedSearchCondition | null;
    assignedUserId: SelectableUser;
}

type Aspect = "contact" | "business" | "community";

interface PageSizeChange {
    aspect: Aspect;
    pageSize: number;
}

interface PageChange {
    aspect: Aspect;
    page: number;
}

interface Sort {
    aspect: Aspect;
    sort: string;
}

export const referenceDashboardSlice = createSlice({
    name: "referenceDashboard",
    initialState: {
        searchFilter: {
            search: "",
            orgTypes: [],
            contactCondition: null,
            businessCondition: null,
            assignedUserId: EVERYONE_SENTINEL,
        } as ReferenceFilter,
        contactSearchRequest: {
            page: 0,
            perPage: 25,
            search: "",
            sort: "numberOfReferrals",
            sortDirection: "DESC",
            assignedUserId: null,
        } as Omit<ReferenceContactSearchRequest, "regionId">,
        businessSearchRequest: {
            page: 0,
            perPage: 25,
            search: "",
            sort: "numberOfReferrals",
            sortDirection: "DESC",
            assignedUserId: null,
        } as Omit<ReferenceBusinessSearchRequest, "regionId">,
        communitySearchRequest: {
            page: 0,
            perPage: 25,
            search: "",
            sort: "numberOfReferrals",
            sortDirection: "DESC",
        } as Omit<ReferenceContactSearchRequest, "regionId">,
    },
    reducers: {
        updateSearch: (state, action: PayloadAction<ReferenceFilter>) => {
            state.searchFilter = action.payload;
            const trimmedSearch = (action.payload.search ?? "").trim();
            state.contactSearchRequest.search = trimmedSearch;
            state.businessSearchRequest.search = trimmedSearch;
            state.communitySearchRequest.search = trimmedSearch;
            state.searchFilter.assignedUserId = action.payload.assignedUserId;
            if (
                action.payload.assignedUserId &&
                action.payload.assignedUserId !== EVERYONE_SENTINEL
            ) {
                state.contactSearchRequest.assignedUserId = action.payload.assignedUserId;
                state.businessSearchRequest.assignedUserId =
                    action.payload.assignedUserId;
            } else {
                state.contactSearchRequest.assignedUserId = null;
                state.businessSearchRequest.assignedUserId = null;
            }
            state.contactSearchRequest.organizationTypes = action.payload.orgTypes;
            state.businessSearchRequest.organizationTypes = action.payload.orgTypes;
            state.contactSearchRequest.contactCondition = action.payload.contactCondition;
            state.contactSearchRequest.businessCondition =
                action.payload.businessCondition;
            state.businessSearchRequest.businessCondition =
                action.payload.businessCondition;
            state.contactSearchRequest.page = 0;
            state.businessSearchRequest.page = 0;
            state.communitySearchRequest.page = 0;
            state.contactSearchRequest.perPage = 25;
            state.businessSearchRequest.perPage = 25;
            state.communitySearchRequest.perPage = 25;
        },
        changePageSize: (state, action: PayloadAction<PageSizeChange>) => {
            const { aspect, pageSize } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                request.perPage = pageSize;
            }
        },
        changePage: (state, action: PayloadAction<PageChange>) => {
            const { aspect, page } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                request.page = page;
            }
        },
        changeSort: (state, action: PayloadAction<Sort>) => {
            const { aspect, sort: column } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                if (request.sort === column) {
                    request.sortDirection =
                        request.sortDirection === "ASC" ? "DESC" : "ASC";
                } else {
                    request.sort = column;
                    request.sortDirection = "ASC";
                }
                request.page = 0;
            }
        },
    },
});
