import { Action } from "redux";
import { RequestState } from "../../constants/loading";
import { RequestDescription } from "../../types/request-description";
import { buildVersion } from "../../util/version";
import { Actions } from "../actions";
import { RequestAction } from "../actions/types";

export function defaultRequestDescription(): RequestDescription {
    return {
        started: null,
        ended: null,
        status: RequestState.NOTSTARTED,
    };
}

export interface StatusState {
    serverBuild: string;
    events: { [id: string]: RequestDescription };
}

export function statusReducer(state: StatusState, action: Action): StatusState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            events: {},
            serverBuild: buildVersion,
        };
    }

    if (action.type === Actions[Actions.EMAIL_FORM_SUBMIT]) {
        const castType = action as RequestAction;
        const description: RequestDescription = {
            started: castType.when,
            ended: null,
            status: RequestState.REQUESTED,
        };
        state = {
            ...state,
            events: { ...state.events, [castType.eventId]: description },
        };
    }

    if (action.type === Actions[Actions.EMAIL_FORM_SUCCESS]) {
        const castType = action as RequestAction;
        const existingDescription: RequestDescription =
            state.events[castType.eventId] || defaultRequestDescription();
        const description = {
            ...existingDescription,
            ended: castType.when,
            status: RequestState.DONE,
            data: castType.data,
        };
        state = {
            ...state,
            events: { ...state.events, [castType.eventId]: description },
        };
    }

    if (action.type === Actions[Actions.EMAIL_FORM_ERROR]) {
        const castType = action as RequestAction;
        const existingDescription: RequestDescription =
            state.events[castType.eventId] || defaultRequestDescription();
        const description = {
            ...existingDescription,
            ended: castType.when,
            status: RequestState.ERROR,
            data: castType.data,
        };
        state = {
            ...state,
            events: { ...state.events, [castType.eventId]: description },
        };
    }

    if (action.type === Actions[Actions.HEARTBEAT]) {
        state = {
            ...state,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            serverBuild: (action as any).data.buildVersion,
        };
    }

    return state;
}
